import React, { useState, useEffect } from "react";
import ConversationSearch from "../ConversationSearch";
import ConversationListItem from "../ConversationListItem";
import Toolbar from "../Toolbar";
import ToolbarButton from "../ToolbarButton";
import { db } from "../../fbconfig";

import "./ConversationList.css";

export default function ConversationList(props) {
  const [conversations, setConversations] = useState([]);
  useEffect(() => {
    db.collection("users").onSnapshot((snapshot) =>
      setConversations(
        snapshot.docs.map((doc) => {
          return {
            photo: `${doc.data().firstName[0] + doc.data().lastName[0]}`,
            name: `${doc.data().firstName} ${doc.data().lastName}`,
            text:
              "Hello world this is a long message that needs to be truncated.",
          };
        })
      )
    );
  }, []);

  return (
    <div className="conversation-list">
      <Toolbar
        title="Messenger"
        leftItems={[<ToolbarButton key="cog" icon="ion-ios-cog" />]}
        rightItems={[
          <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />,
        ]}
      />
      <ConversationSearch data={conversations} />
      {conversations.map((conversation) => (
        <ConversationListItem key={conversation.name} data={conversation} />
      ))}
    </div>
  );
}
