import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { db, auth } from "../fbconfig";
import { Link, Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const Admin = () => {
  const classes = useStyles();
  const [users, setUsers] = useState("");
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    auth &&
      db
        .collection("users")
        .where("id", "==", auth.currentUser.uid)
        .onSnapshot((snapshot) =>
          snapshot.docs.map((doc) => setUserRole(doc.data().role))
        );
  }, []);

  useEffect(() => {
    db.collection("users").orderBy("firstName").onSnapshot((snapshot) => {
      setUsers(snapshot.docs.map((doc) => doc.data()));
    });
  }, []);

  if (userRole && userRole !== "admin") return <Redirect to="/" />;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.title}>
          Регистрирани потребители: {users && users.length}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Ученици</Typography>
        <Paper className={classes.paper}>
          <List dense>
            {users &&
              users.map((user, index) => {
                return (
                  user.role !== "admin" && (
                    <Link
                      to={`/user/${user.id}`}
                      key={index}
                      style={{ color: "#000" }}
                    >
                      <ListItem button>
                        <ListItemAvatar>
                          <Avatar>
                            {user.firstName[0] + user.lastName[0]}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={user.firstName + " " + user.lastName}
                          secondary={user.email}
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Link>
                  )
                );
              })}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Typography>Администратор</Typography>
        <Paper className={classes.paper}>
          <List dense>
            {users &&
              users.map((user, index) => {
                return (
                  user.role === "admin" && (
                    <Link
                      to={`/user/${user.id}`}
                      key={index}
                      style={{ color: "#000" }}
                    >
                      <ListItem button>
                        <ListItemAvatar>
                          <Avatar>
                            {user.firstName[0] + user.lastName[0]}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={user.firstName + " " + user.lastName}
                          secondary={user.email}
                        />
                      </ListItem>
                    </Link>
                  )
                );
              })}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Admin;
