import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { Context } from "../Context";
import { db } from "../fbconfig";
import { UserContext } from "../UserProvider";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import BeatLoader from "react-spinners/BeatLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Subject = (props) => {
  const classes = useStyles();

  const id = props.match.params.id;
  const { subjects, questions } = useContext(Context);
  const user = useContext(UserContext);
  const [answer, setAnswer] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [randomQ, setRandomQ] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [answered, setAnswered] = useState(false);

  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user)
      db.collection("work")
        .doc(user.displayName)
        .onSnapshot((snapshot) => {
          setAnswers(snapshot.data());
          if (snapshot.data()?.choosenQuestion)
            setAnswer(snapshot.data()?.choosenQuestion);
          setIsLoading(false);
        });
  }, [user]);

  const subject = subjects.filter((s) => s.id === id)[0]?.title;
  const subjectQuestions = questions.filter((q) => q.id === id);

  const shuffleQuestion = (e) => {
    e.preventDefault();
    const randomQuestion = Math.floor(Math.random() * subjectQuestions.length);
    setRandomQ(subjectQuestions[randomQuestion]);
    db.collection("work")
      .doc(user?.displayName)
      .set({
        ...answers,
        choosenQuestion: [
          ...answer,
          {
            questionID: id,
            clicked: true,
            questionTitle: subjectQuestions[randomQuestion].title,
            questionSubtitle: subjectQuestions[randomQuestion].subtitle,
          },
        ],
      });
  };

  const handleChange = (content) => {
    setContent(content);
  };

  const filterOut = answer?.filter((ans) => ans.questionID !== id);
  const filterAnswer = answer?.filter((ans) => ans.questionID === id)[0];

  const handleSubmit = () => {
    setLoading(false);
    const reply = {
      ...filterAnswer,
      content,
      answered: true,
      sended_at: new Date(),
    };
    db.collection("work")
      .doc(user.displayName)
      .set({ choosenQuestion: [...filterOut, reply] })
      .then((res) => {
        setLoading(true);
        setAnswered(true);
      })
      .catch((err) => setError(err.message));
  };

  const handlePaste = (e) => {};

  return (
    <Grid>
      <Grid item xs={12}>
        <h1 style={{ textAlign: "center" }}>{subject}</h1>
        <Divider />
      </Grid>
      {filterAnswer ? (
        <Grid container>
          <Grid item xs={12} align="center">
            <Typography variant="h5">{filterAnswer.questionTitle}</Typography>
            <Typography color="textSecondary">
              {filterAnswer.questionSubtitle}
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Divider />
            <Typography variant="h4">Успех !</Typography>
            <Grid item xs={12} align="center">
              {!filterAnswer.answered && !answered ? (
                <div className="editor">
                  <SunEditor
                    onPaste={handlePaste}
                    setOptions={{
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          "font",
                          "fontSize",
                          "formatBlock",
                          "paragraphStyle",
                          "blockquote",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                          "fontColor",
                          "hiliteColor",
                          "textStyle",
                          "removeFormat",
                          "outdent",
                          "indent",
                          "align",
                          "horizontalRule",
                          "list",
                          "lineHeight",
                          "table",
                          "link",
                          "image",
                          "video",
                          "imageGallery",
                          "fullScreen",
                        ],
                      ],
                      width: "100%",
                      height: "50vh",
                    }}
                    onChange={handleChange}
                    name="editor"
                  />
                  <div className={classes.buttons}>
                    <div className="divider"></div>
                    {/*<Button
                      color="primary"
                      onClick={handleSave}
                      startIcon={<CloudUploadIcon />}
                      disabled={!content}
                    >
                      Запиши като чернова
                    </Button> */}
                    {!loading ? (
                      <ClipLoader color="blue" />
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSubmit}
                        startIcon={<SaveIcon />}
                        disabled={!content}
                      >
                        Изпрати
                      </Button>
                    )}
                  </div>
                </div>
              ) : filterAnswer.answered ? (
                <Alert severity="success">Отговорът е изпратен успешно !</Alert>
              ) : (
                error && <Alert severity="error">Грешка !</Alert>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        !isLoading &&
        subjectQuestions.length !== 0 && (
          <Grid item xs={12} align="center">
            <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <BeatLoader color="blue" />
            </div>
            <Button variant="contained" onClick={shuffleQuestion}>
              Изтегли въпрос
            </Button>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default Subject;
