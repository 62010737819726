import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const AdminLinks = () => {
  return (
    <>
      <Link to="/admin">
        <Button color="inherit">Admin Panel</Button>
      </Link>
    </>
  );
};

export default AdminLinks;
