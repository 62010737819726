import React, { useEffect } from "react";
import shave from "shave";
import { Avatar } from "@material-ui/core";

import "./ConversationListItem.css";

export default function ConversationListItem(props) {
  useEffect(() => {
    shave(".conversation-snippet", 20);
  });

  const { photo, name, text } = props.data;

  return (
    <div className="conversation-list-item">
      <Avatar className="conversation-photo">{photo}</Avatar>
      <div className="conversation-info">
        <h1 className="conversation-title">{name}</h1>
        <p className="conversation-snippet">{text}</p>
      </div>
    </div>
  );
}
