import React from "react";
import Nav from "./components/Nav";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Subject from "./components/Subject";
import Login from "./components/Login";
import Register from "./components/Register";
import Messenger from "./components/Messenger";
import { Switch, Route } from "react-router-dom";
import { Container } from "@material-ui/core";
import Admin from "./Admin/Admin";
import UserInfo from "./Admin/UserInfo";

function App() {
  return (
    <>
      <header>
        <Nav />
      </header>
      <main>
        <Container style={{ marginTop: "10px" }}>
          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} />} />
            <Route
              path="/subject/:id"
              render={(props) => <Subject {...props} />}
            />
            <Route path="/Profile" component={Profile} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/admin" component={Admin} />
            <Route path="/user/:id" component={UserInfo} />
          </Switch>
        </Container>
        <Route path="/users" component={Messenger} />
      </main>
      <footer>&copy; {new Date().getFullYear()}</footer>
    </>
  );
}

export default App;
