import React, { createContext } from "react";

export const Context = createContext({
  subjects: "",
  questions: "",
});
const ContextProvider = (props) => {
  const subjects = [
    { id: "1", title: "ТЕМА №1 - МРЕЖОВИ ТЕХНОЛОГИИ В БИЗНЕСА", done: true },
    { id: "2", title: "ТЕМА №2 - МРЕЖОВИ ПРОТОКОЛИ", done: true },
    { id: "3", title: "ТЕМА №3 - МРЕЖОВИ ОПЕРАЦИОННИ СИСТЕМИ", done: true },
    { id: "4", title: "ТЕМА №4 - БИЗНЕС КОМУНИКАЦИИ В WEB", done: true },
    {
      id: "5",
      title: "ТЕМА №5 - ЕЛЕКТРОННА ПОЩА, ТЪРСЕНЕ В ИНТЕРНЕТ",
      done: true,
    },
    { id: "6", title: "ТЕМА №6 - ЕЛЕКТРОННО БАНКИРАНЕ", done: true },
    {
      id: "7",
      title: "ТЕМА №7 - ИНТЕРНЕТ ТЕХНОЛОГИИТЕ В БАНКОВАТА СИСТЕМА",
      done: true,
    },
    { id: "8", title: "ТЕМА №8 - WEB ПРИЛОЖЕНИЯ И  WEB СЪРВЪРИ", done: true },
    { id: "9", title: "ТЕМА №9 - ПРОЕКТИРАНЕ НА УЕБ САЙТ" },
    {
      id: "10",
      title: "ТЕМА №10 - РАЗРАБОТВАНЕ НА НАБОРИ ОТ СТИЛОВЕ",
    },
    { id: "11", title: "ТЕМА №11- РАБОТА С ГРАФИКА" },
    { id: "12", title: "ТЕМА №12 - ДОБАВЯНЕ НА ПОТРЕБИТЕЛСКА ИНТЕРАКТИВНОСТ" },
    { id: "13", title: "ТЕМА №13 - ЕЗИКЪТ HTML ЗА СЪЗДАВАНЕ НА УЕБ СТРАНИЦИ" },
    { id: "14", title: "ТЕМА №14 - СЪЗДАВАНЕ НА ГРАФИЧНИ ОБЕКТИ" },
    { id: "15", title: "ТЕМА №15 - ВИДОВЕ ИЗОБРАЖЕНИЯ" },
    {
      id: "16",
      title: "ТЕМА №16 - ФОРМАТИРАНЕ НА WEB СТРАНИЦИ СЪС СТИЛОВЕ (CSS)",
    },
    { id: "17", title: "ТЕМА №17 - РАЗРАБОТВАНЕ НА САЙТ ЗА ТЪРГОВСКА ФИРМА" },
    { id: "18", title: "ТЕМА №18 - РАЗРАБОТВАНЕ НА САЙТ ЗА ПОРЪЧКА НА СТОКИ" },
  ];

  const questions = [
    {
      id: "1",
      title:
        "Какво представлява компютърната мрежа ? Видове компютърни мрежи(класификация).",
      subtitle:
        "Същност; Условия за работа в комопютърна мрежа; Класификация според обхвата; Класификация според управлението си;",
    },
    { id: "1", title: "Мрежови топологии. Примери.", subtitle: "" },
    { id: "1", title: "Мрежови хардуер.", subtitle: "" },

    {
      id: "9",
      title:
        "Опишете стъпките за създаване на уеб сайт чрез Photoshop и Dreamweaver",
      subtitle:
        "Запишете по какъв начин създавате един уеб сайт в табличен дизайн. Как се създават таблици в Dreamweaver. Как се форматират клетки. Пример за вложена таблица.",
    },
    {
      id: "10",
      title: "Същност и предназначение на Каскадните набори от стилове (CSS).",
      subtitle: "Какво представлява CSS и за какво се използва.",
    },
    {
      id: "10",
      title: "Видове CSS.",
      subtitle: "Избройте видовете стилове и пояснете с примери.",
    },
    {
      id: "11",
      title: "Инструменти във Photoshop.",
      subtitle:
        "Избройте инструментите, които познавате във Photoshop и опишете, какво действие извършва всеки един от тях.",
    },
    {
      id: "11",
      title: "Видове графични формати използвани в уеб пространството.",
      subtitle: "Избройте видовете графични формати и за какво се използват.",
    },
    { id: "11", title: "Действия с обекти в CorelDraw", subtitle: "" },
    {
      id: "12",
      title: "Rollover изображение/бутон.",
      subtitle:
        "Какво означава едно изображение/бутон да е 'Rollover'. Опишете начина, по който се добавя 'Rollover' изображение/бутон в Dreamweaver. Как можем да използваме 'Rollover' изображение/бутон.",
    },
    {
      id: "12",
      title: "Изображения-карти.",
      subtitle:
        "Какво означава изображение-карта в Dreamweaver. Как се създава изображение-карта. Как можем да използваме изображение-карта.",
    },
    {
      id: "13",
      title: "Какво представлява езикът HTML?",
      subtitle:
        "Къде се използва HTML език. Какво е HTML файл. Софтуер за работа с HTML. Какво е общото между браузър и HTML файл.",
    },
    {
      id: "13",
      title: "Структура на HTML документ.",
      subtitle:
        "Какво е HTML файл. Как запазваме HTML файла. Какво представлява таг в HTML. Какво е общото между браузър и HTML файл.",
    },
    {
      id: "13",
      title: "Елементи(тагове) в HTML документ.",
      subtitle:
        "Какво представлява таг в HTML. Какво представлява атрибут в тага. Основни тагове в уеб сайт и примери. ",
    },
    {
      id: "14",
      title: "Обработка на векторни изображения в средата на Corel Draw.",
      subtitle:
        "Векторно изображение(същност и примери). Какво е обект в CorelDraw. Какви действия можем да извършваме с обекти в CorelDraw.",
    },
    {
      id: "14",
      title: "Обработка на растерни изображения в средата на Adobe Photoshop",
      subtitle:
        "Растерно изображение(същност и примери). Инструменти за селекция. Какво е слой във Photoshop. Действия със слоеве.",
    },
    {
      id: "14",
      title: "Инструменти в Adobe Photoshop.",
      subtitle:
        "Избройте основните инструменти във Photoshop. Опишете накратко за какво служи всеки един от тях.",
    },
    {
      id: "15",
      title: "Видове компютърна графика и характеристиките им.",
      subtitle:
        "Избройте видовете компютърна графика и ги опишете. Недостатъци и предимства.",
    },
    {
      id: "15",
      title: "Графични формати при векторната и растерната графика.",
      subtitle:
        "Определение за растерна и векторна графика. Избройте основните видове графични формати и опишете за какво или къде се използват.",
    },
    {
      id: "16",
      title:
        "Същност и предназначение на каскадните набори от стилове (Cascading Style Sheets, CSS).",
      subtitle:
        "За какво служи CSS. Къде и как се използва CSS. Запишете примери за CSS свойство. (напр. промяна цвета на текст).",
    },
    {
      id: "16",
      title: "Видове CSS (Cascading Style Sheets). ",
      subtitle:
        "Избройте и опишете видовете CSS. Посочете, кой вид е с най-висок приоритет и дайте примери.",
    },
    {
      id: "17",
      title: "Етапи и стъпки при разработване на сайт за търговска фирма.",
      subtitle:
        "Избройте основните етапи при проектиране на уеб сайт. Какви технологии се използват при създаване на ДИНАМИЧЕН уеб сайт. Какво означва да планираме сайт. Какви данни трябва да съдържа БД(базата от данни) в сайт за търговска фирма.",
    },
    {
      id: "17",
      title: "Етапи и стъпки при разработване на сайт за търговска фирма.",
      subtitle:
        "Какво означва да планираме сайт. Какви данни трябва да съдържа БД(базата от данни) в сайт за търговска фирма.",
    },
    {
      id: "18",
      title: "Етапи и стъпки при разработване на сайт за поръчка на стоки.",
      subtitle:
        "Избройте основните етапи при проектиране на уеб сайт. Какви технологии се използват при създаване на ДИНАМИЧЕН уеб сайт. Какво означва да планираме сайт. Какви данни трябва да съдържа БД(базата от данни) в сайт за поръчка на стоки",
    },
    {
      id: "18",
      title: "Етапи и стъпки при разработване на сайт за поръчка на стоки.",
      subtitle:
        "Какво означва да планираме сайт. Какви данни трябва да съдържа БД(базата от данни) в сайт за поръчка на стоки",
    },
  ];

  return (
    <Context.Provider value={{ subjects, questions }}>
      {props.children}
    </Context.Provider>
  );
};

export default ContextProvider;
