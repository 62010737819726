import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyDIHELKXgJzIbQAyzqmoIsLSf-dAb6Y6VY",
  authDomain: "dki-project-62827.firebaseapp.com",
  projectId: "dki-project-62827",
  storageBucket: "dki-project-62827.appspot.com",
  messagingSenderId: "742852932906",
  appId: "1:742852932906:web:5c81a532bd6a8dd2835a53",
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();