import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import ContextProvider from "./Context";
import UserProvider from "./UserProvider";
import { auth } from "./fbconfig";

auth.onAuthStateChanged(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <UserProvider>
          <ContextProvider>
            <App />
          </ContextProvider>
        </UserProvider>
      </Router>
    </React.StrictMode>,
    document.getElementById("app")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
