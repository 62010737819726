import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { db, auth } from "../fbconfig";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { Fragment } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  summary: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    marginLeft: "1rem",
  },
}));

const UserInfo = (props) => {
  const classes = useStyles();

  const id = props.match.params.id;
  const [user, setUser] = useState("");
  const [userWork, setUserWork] = useState("");
  const [review, setReview] = useState("");
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    auth &&
      db
        .collection("users")
        .where("id", "==", auth.currentUser.uid)
        .onSnapshot((snapshot) =>
          snapshot.docs.map((doc) => setUserRole(doc.data().role))
        );
  }, []);

  useEffect(() => {
    db.collection("users")
      .where("id", "==", id)
      .onSnapshot((snapshot) =>
        snapshot.docs.map((doc) => setUser(doc.data()))
      );
  }, [id]);

  useEffect(() => {
    user &&
      db
        .collection("work")
        .doc(user.firstName + " " + user.lastName)
        .onSnapshot((snapshot) =>
          setUserWork(snapshot.data()?.choosenQuestion)
        );
  }, [user]);

  const clearData = (e) => {
    const filterWork = userWork.filter(
      (uw) => uw.questionTitle === e.target.id
    )[0];
    const elseWork = userWork.filter((uw) => uw.questionTitle !== e.target.id);
    const copyOfWork = {
      answered: false,
      questionID: filterWork.questionID,
      clicked: filterWork.clicked,
      questionTitle: filterWork.questionTitle,
      questionSubtitle: filterWork.questionSubtitle,
    };
    db.collection("work")
      .doc(user.firstName + " " + user.lastName)
      .set({
        choosenQuestion: [{ ...copyOfWork }, ...elseWork],
      })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const deleteData = (e) => {
    const elseWork = userWork.filter((uw) => uw.questionTitle !== e.target.id);
    db.collection("work")
      .doc(user.firstName + " " + user.lastName)
      .set({
        choosenQuestion: [...elseWork],
      })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const handleReviewSubmit = (e) => {
    e.preventDefault();
    const filterWork = userWork.filter(
      (uw) => uw.questionTitle === e.target.id
    )[0];
    const elseWork = userWork.filter((uw) => uw.questionTitle !== e.target.id);
    const reviewOfWork = {
      ...filterWork,
      review,
    };
    db.collection("work")
      .doc(user.firstName + " " + user.lastName)
      .set({
        choosenQuestion: [{ ...reviewOfWork }, ...elseWork],
      })
      .then(() => {
        setReview("");
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  if (userRole && userRole !== "admin") return <Redirect to="/" />;
  return (
    <div>
      {user && userWork ? (
        <>
          <h1>{user.firstName + " " + user.lastName}</h1>
          <Divider />
          <Grid container>
            {userWork.length > 0 ? (
              userWork.map((work, index) => (
                <Fragment key={index}>
                  <Grid item xs={8} md={10} style={{ marginTop: "1rem" }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className={classes.summary}>
                          <Typography className={classes.heading}>
                            <b>Тема №{work.questionID}</b> -{" "}
                            {work.questionTitle}
                          </Typography>
                          {work.sended_at && (
                            <Typography className={classes.secondaryHeading}>
                              Изпратен на:{" "}
                              {moment(work.sended_at.toDate()).format(
                                "DD.MM.YYYY | HH:mm "
                              )}
                            </Typography>
                          )}
                          {work.review && (
                            <Typography
                              className={classes.secondaryHeading}
                              style={{ color: "green" }}
                            >
                              Проверено
                            </Typography>
                          )}
                        </div>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails>
                        {!work.content ? (
                          <p style={{ color: "red" }}>
                            Избран е въпрос, но липсва изпратен отговор.
                          </p>
                        ) : (
                          <Grid item>
                            <Typography color="textSecondary">
                              {work.questionSubtitle}
                            </Typography>
                            <Typography variant="h6">
                              Изпратен отговор:
                            </Typography>
                            <Typography
                              dangerouslySetInnerHTML={{ __html: work.content }}
                            />
                          </Grid>
                        )}
                      </AccordionDetails>
                      <Divider />
                      <Grid item style={{ padding: "1rem" }}>
                        <TextField
                          rows={5}
                          multiline
                          label="Рецензия"
                          placeholder="..."
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={(e) => setReview(e.target.value)}
                        />
                        <Button
                          id={work.questionTitle}
                          variant="outlined"
                          style={{ marginTop: "1rem" }}
                          onClick={handleReviewSubmit}
                        >
                          Изпрати
                        </Button>
                      </Grid>
                      <Divider />
                      {work.review ? (
                        <Grid item style={{ padding: "1rem" }}>
                          <Typography variant="h6">
                            Изпратена рецензия:
                          </Typography>
                          <Typography style={{ color: "green" }}>
                            {work.review}
                          </Typography>
                        </Grid>
                      ) : (
                        <Typography
                          style={{ padding: "1rem", color: "red" }}
                          variant="h6"
                        >
                          Липсва рецензия
                        </Typography>
                      )}
                    </Accordion>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    md={2}
                    style={{ marginTop: "1rem", justifyContent: "flex-end" }}
                  >
                    <IconButton onClick={clearData} id={work.questionTitle}>
                      <BackspaceIcon />
                    </IconButton>
                    <IconButton onClick={deleteData} id={work.questionTitle}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Grid>
                </Fragment>
              ))
            ) : (
              <Typography variant="h4" component="h4">
                Липсват изпратени или избрани въпроси.
              </Typography>
            )}
          </Grid>
        </>
      ) : (
        <ClipLoader />
      )}
    </div>
  );
};

export default UserInfo;
