import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../UserProvider";
import { auth, db } from "../fbconfig";
import { Redirect } from "react-router";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { Fragment } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/bg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  summary: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Profile = () => {
  const classes = useStyles();

  const user = useContext(UserContext);
  const [userWork, setUserWork] = useState("");

  useEffect(() => {
    user &&
      db
        .collection("work")
        .doc(user.displayName)
        .onSnapshot((snapshot) =>
          setUserWork(snapshot.data()?.choosenQuestion)
        );
  }, [user]);

  if (!auth.currentUser) return <Redirect to="/" />;
  return (
    <>
      <Grid container className="profile">
        <Grid item xs={12}>
          <h1>Потребител: {user?.displayName}</h1>
          <h2>Email: {user?.email}</h2>
          <p>
            Създаден:{" "}
            <span>
              <i>
                {moment(user?.metadata.creationTime)
                  .locale("bg")
                  .format("LLLL")}
              </i>
            </span>
          </p>
          <Divider />
        </Grid>
        {userWork ? (
          <Grid item xs={12}>
            <Typography>
              Изпратени отговори за проверка (или избрани въпроси):
              <b>{userWork.length}</b>
            </Typography>
            {userWork.length > 0 &&
              userWork.map((work, index) => (
                <Fragment key={index}>
                  <Grid item xs={12} style={{ marginTop: "1rem" }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className={classes.summary}>
                          <Typography className={classes.heading}>
                            <b>Тема №{work.questionID}</b> -{" "}
                            {work.questionTitle}
                          </Typography>
                          {work.sended_at && (
                            <Typography className={classes.secondaryHeading}>
                              Изпратен на:{" "}
                              {moment(work.sended_at.toDate()).format(
                                "DD.MM.YYYY | HH:mm "
                              )}
                            </Typography>
                          )}
                          {work.review && (
                            <Typography
                              className={classes.secondaryHeading}
                              style={{ color: "green", marginLeft: "1rem" }}
                            >
                              Проверено
                            </Typography>
                          )}
                        </div>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails>
                        {!work.content ? (
                          <p style={{ color: "red" }}>
                            Избран е въпрос, но липсва изпратен отговор.
                          </p>
                        ) : (
                          <Grid item>
                            <Typography variant="h6">
                              Вашият отговор:
                            </Typography>
                            <Typography
                              dangerouslySetInnerHTML={{ __html: work.content }}
                            />
                          </Grid>
                        )}
                      </AccordionDetails>
                      <Divider />
                      {work.review && (
                        <Grid item style={{ padding: "1rem" }}>
                          <Typography variant="h6" style={{ color: "red" }}>
                            Рецензия:
                          </Typography>
                          <Typography>{work.review}</Typography>
                        </Grid>
                      )}
                    </Accordion>
                  </Grid>
                </Fragment>
              ))}
          </Grid>
        ) : (
          <Typography>
            Изпратени отговори за проверка (или избрани въпроси):
            <b>0</b>
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default Profile;
