import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { auth } from "../fbconfig";
import { UserContext } from "../UserProvider";
import SignInLinks from "./auth/SignInLinks";
import SignOutLinks from "./auth/SignOutLinks";
import { deepPurple } from "@material-ui/core/colors";
import HomeIcon from "@material-ui/icons/Home";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[300],
  },
}));

const Nav = () => {
  const user = useContext(UserContext);
  const classes = useStyles();

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <Link to="/">
            <Button
              startIcon={<HomeIcon edge="start" />}
              size="large"
              color="inherit"
            >
              ДИ за СПК - {new Date().getFullYear()}
            </Button>
          </Link>
          <a href="https://dki.sgivt.com/" target="_blank">
            Проверка на резултат от ДИ за СПК
          </a>
        </Typography>
        {user ? (
          <SignInLinks auth={auth} classes={classes} user={user} />
        ) : (
          <SignOutLinks />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
