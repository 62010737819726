import { Avatar, Button, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import { db } from "../../fbconfig";
import AdminLinks from "../../Admin/AdminLinks";

const SignInLinks = ({ classes, auth, user }) => {
  /* const updateUserName = () => {
    auth.currentUser.updateProfile({ displayName: "Васил Василев" });
  }; */

  const [userInitials, setUserInitials] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    user &&
      db
        .collection("users")
        .doc(user?.uid)
        .onSnapshot((snapshot) => {
          setUserInitials(snapshot.data());
          setIsLoading(false);
        });
  }, [user]);

  return (
    <>
      {!isLoading && userInitials && userInitials.role === "admin" && (
        <AdminLinks />
      )}
      <Link to="/profile">
        <IconButton color="inherit">
          {!isLoading ? (
            <Avatar className={classes.small}>
              {userInitials.firstName[0] + userInitials.lastName[0]}
            </Avatar>
          ) : (
            <Avatar className={classes.small}>
              <PersonIcon />
            </Avatar>
          )}
        </IconButton>
      </Link>
      <Button onClick={() => auth.signOut()} color="inherit">
        Изход
      </Button>
    </>
  );
};

export default SignInLinks;
