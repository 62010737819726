import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const SignOutLinks = () => {
  return (
    <>
      <Link to="/register">
        <Button color="inherit">Регистрация</Button>
      </Link>
      <Link to="/login">
        <Button color="inherit">Вход</Button>
      </Link>
    </>
  );
};

export default SignOutLinks;
