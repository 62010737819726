import React, { useContext, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Button, Typography } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { Context } from "../Context";
import { auth } from "../fbconfig";

const Home = () => {
  const { subjects } = useContext(Context);
  if (!auth.currentUser) return <Redirect to="/register" />;
  return (
    <div className="home">
      <Typography variant="h3">Теми за ДИ за СПК</Typography>
      <List>
        {subjects &&
          subjects.map((subject) => (
            <Fragment key={subject.id}>
              <ListItem>
                <ListItemText
                  primary={subject.title}
                  className={subject.done && "disabled"}
                />
                {subject.done ? (
                  <Button variant="outlined" disabled>
                    Отвори
                  </Button>
                ) : (
                  <Link to={`/subject/${subject.id}`}>
                    <Button variant="outlined">Отвори</Button>
                  </Link>
                )}
              </ListItem>
              <hr />
            </Fragment>
          ))}
      </List>
    </div>
  );
};

export default Home;
